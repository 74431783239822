<template>
 
  <section class="faqs">
      <div class="container">
          <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Preguntas <span>frecuentes</span> </h1>
            </div>
          </div>
          <div class="accordion mt-4">
              <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿Qué es Credix?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    Credix una financiera y fiduciaria con más de 15 años de trayectoria en el sector financiero mexicano, ofreciendo alternativas de financiamiento, factoraje y servicios fiduciarios en comparativa de la banca tradicional o cualquier otra institución financiera.
                </p>
                <p>En Credix queremos ayudar a todas esas personas y PyMEs a fortalecer su capital de trabajo, brindarles liquidez inmediata o bien hacer realidad ese proyecto que tanto anhelan.</p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿Credix es un banco?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    No, Credix no es un banco, Credix es una SOFOM NO REGULADA la cual forma parte de las entidades que funcionan como una alternativa 
                    para poder adquirir un financiamiento o crédito, así como realizar la constitución de fideicomisos en garantía. Estas deben contar con un registro 
                    vigente en CONDUSEF para poder operar y están sujetas a la inspección y vigilancia de la CNBV, pero únicamente en materia de prevención del
                    lavado de dinero y financiamiento al terrorismo.
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿Cuál es la clave de Registro de Credix en CONDUSEF?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    Nuestra <b>clave de registro</b> ante la CONDUSEF es: <b>690037</b><br>
                    Puedes corroborar nuestro registro ante la Condusef en el siguiente link: https://webapps.condusef.gob.mx/SIPRES/jsp/pub/index.jsp<br>
                    <br>
                    Pasos a seguir:<br>
                    <ol>
                        <li>En el primer campo de búsqueda "Nombre o nombre corto" colocar "CREDIX GS"</li>
                        <li>Dar click en buscar y seleccionar "CREDIX GS, S.A. de C.V., SOFOM ENR" la cual es nuestra razón social</li>
                        <li>Se abrirá una ventana con nuestro registro donde el usuario podrá visualizar domicilio, estatus ante CONDUSEF, datos del representante legal,</li>
                        fecha de inicio de operaciones, entre muchos
                    </ol>
        
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Si estoy en buró de crédito ¿puedo aplicar a un préstamo con garantía inmobiliaria?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    Sí, uno de nuestros diferenciadores en Credix es que el buró de crédito NO es determinante para obtener una línea de crédito con nosotros.
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿Cómo funciona un crédito con garantía inmobiliaria?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    Credix es una SOFOM enfocada en brindar líneas de crédito con garantía inmobiliaria. Esto es que todo solicitante nuestro utiliza un bien inmueble como una casa, departamento, oficina, edificio o terreno para garantizar el crédito.
                </p>
                <p>
                    Con este esquema accedes a montos de crédito superiores a los de la banca tradicional, mejores tasas de interés y plazos de préstamo más flexibles.
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿Si no cuento con bien inmueble puedo solicitar un crédito?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    No, actualmente no otorgamos préstamos sin un activo que lo respalde. Nuestros créditos están garantizados con un bien inmueble. Al ofrecer el activo en garantía, podemos ofrecer mejores montos, tasas de interés competitivas y plazos más flexibles.
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿Puedo solicitar un préstamo si soy extranjero?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                   Si, si es posible que acreditemos a extranjeros en la financiera. Para efecto de lo anterior el cliente deberá de contar, cómo adicional a nuestros requerimientos originales: 
                     <br><br>
                      <ul>
                          <li>Contar con residencia permanente o temporal (antes FM3 y FM2).</li>
                          <li>Pasaporte vigente.</li>
                          <li>Acta de nacimiento de país de origen.</li>
                      </ul>
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">¿En dónde se encuentran ubicados?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    Actualmente contamos con oficinas de representación en CDMX, Jalisco, Nuevo León, San Luis Potosí y Chihuahua, puedes visitarnos en nuestros domicilios:
                </p>
                <p>
                    <ul>
                        <!-- <li>
                            <b>Michoacán</b>: Periférico Paseo de la República 2650, Piso 4, interior 5, Colonia Prados de Campestre, C.P. 58297, Morelia, Michoacán.
                        </li> -->
                        <li>
                            <b>Ciudad de México</b>: Miguel de Cervantes Saavedra #157, Piso.- 6 Col. Ampliación Granada, delegación Miguel Hidalgo, C.P.11520, Ciudad de México.
                        </li>
                        <li>
                             <b>Jalisco</b>: C. Paseo de los Virreyes 45 Piso 4 Int. 116 Col. Puerta de Hierro, C.P. 45116 Zapopan Jalisco.
                        </li>
                        <li>
                             <b>Nuevo León</b>: Av. Insurgentes 4001 Int-A Col. Colinas de San Jerónimo C.P. 64630 Monterrey Nuevo León.
                        </li>
                        <li>
                             <b>San Luis Potosí	</b>: Av. Sierra Leona 360, Piso 9 Int. A ("Alttus Corporate Center & Plaza") Col. Villantigua, C.P. 78214, San Luis Potosí, San Luis Potosí.
                        </li>
                        <li>
                             <b>Chihuahua</b>: Via Trentino No.5170 int.402, Colonia Saucito C.P. 31110, Chihuahua.
                        </li>
                    </ul>
                </p>
                <p>
                    Nuestras oficinas centrales y de la UNE se encuentran en Morelia, Michoacán en: <br><br> Leibnitz 117 Anzures, Miguel Hidalgo, 11590 PISO 5 <br><br>
                    Nuestro horario de atención es de lunes a viernes de 09:00 a.m. a 06:00 p.m.  <br>
                    Ponemos a tu disposición nuestros medios de contacto:
                    <br>
                    <ul>
                        <li>Corporativo: 800 467 2836 <br> Oficina Central CDMX: 55 4333 0223</li>
                        <li>WhatsApp: 55 3510 7320</li>
                        <li>hola@credix.com.mx</li>
                    </ul>
                </p>
                </template>
            </Pregunta>
        </div>
      </div>
  </section>
</template>

<script>
import Pregunta from "@/components/otros/Accordion";
export default {
components:{
    Pregunta
},
 methods:{
        
    }

}
</script>

<style lang="css">
    .faqs{
        margin: 4rem 0;
    }
</style>